import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { DataService } from '../core/services/data.service'
import { Router } from '@angular/router'
import { checkAuthorized  } from '../core/utilities/auth-util'
@Component({
  selector: 'app-access-denied-page',
  templateUrl: './access-denied-page.component.html',
  styleUrls: ['./access-denied-page.component.scss']
})
export class AccessDeniedPageComponent implements OnInit, OnDestroy {
  userSubscription: Subscription = new Subscription()

  constructor(private router: Router, private dataService: DataService) { }

  ngOnInit(): void {
    this.userSubscription = this.dataService.userSubject.subscribe({
      next: (res) => {
        // console.log('res:'); console.log(res)
        let authorized = checkAuthorized(res, this.router)
        if (authorized === true) this.router.navigate(['/dashboard'])
        this.userSubscription.unsubscribe()
      }, error: (error) => { 
        console.error(error)
        this.userSubscription.unsubscribe() 
      }
    })
  }

  ngOnDestroy(): void {
    if (this.userSubscription) this.userSubscription.unsubscribe()
  }
}
