import { Component, OnInit, OnDestroy } from '@angular/core'
import { DataService } from '../core/services/data.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { InformationalModalComponent } from 'bvd-modular-components'
import { RebarAuthService } from '../core/auth/rebar.auth.service'
import { environment } from '../../environments/environment'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { checkAuthorized } from '../core/utilities/auth-util'
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core'
import clone from 'just-clone'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public version: string = '(' + environment.version + ')' || ''
  footerText: string = '&copy; 2024 Accenture. All rights reserved. Accenture Confidential. For internal use only. Version V' + this.version
  navMetaData: any = {}
  authorized: string = 'authorizing'
  env: string = environment.environment

  idleState = 'Not started.'
  timedOut = false
  modalRef: any = null
  idlePeriod: number = 28800
  timeoutPeriod: number = 300

  demoEids: any = {
    'test.level11': { eid: 'test.level11', level: '13', country: 'USA', isInServiceDesk: false },
    'test.level7': { eid: 'test.level7', level: '7', country: 'USA', isInServiceDesk: false },
    'test.level6': { eid: 'test.level6', level: '6', country: 'USA', isInServiceDesk: false },
    'test.workstation.security': { eid: 'test.workstation.security', level: '9', country: 'USA', isInServiceDesk: true },
  }
  realEid: any = null

  authSubscription: Subscription = new Subscription()
  userSubscription: Subscription = new Subscription()
  tokenSubscription: Subscription = new Subscription()
  onIdleEndSubscription: Subscription = new Subscription()
  onTimeoutSubscription: Subscription = new Subscription()
  onIdleStartSubscription: Subscription = new Subscription()
  onTimeoutWarningSubscription: Subscription = new Subscription()
  avatarSubscription: Subscription = new Subscription()

  constructor(private router: Router, private dataService: DataService, private modalService: NgbModal, private rebarAuthService: RebarAuthService, private idle: Idle) { }

  ngOnInit() {
    this.authSubscription = this.rebarAuthService.authenticatedSubject.subscribe(authStatus => {
      if (authStatus) {
        this.userSubscription = this.dataService.getUserData().subscribe({
          next: (res: any) => {
            // console.log(res)
            if (res && res.eid) {
              this.navMetaData.userName = res.eid
              this.authorized = checkAuthorized(res, this.router) ? 'authorized' : 'not authorized'
              if (!res.eid.includes('test.') && this.env !== 'prod') {
                this.realEid = res
                this.navMetaData.endItem.options.unshift({ type: 'link', name: res.eid, function: this.switchUser.bind(this, res.eid) })
                // this.switchUser('test.level6') // TODO: delete, only for demo purposes
              }
              if (this.env !== 'local') {
                this.tokenSubscription = this.dataService.getAccessToken('api').subscribe({ 
                  next: (res: any) => { // only start watching for idle status if there's an id token to revoke
                    if (res) this.startIdle()
                    else this.router.navigate(['timeout'])
                  }, error: (error: any) => console.error(error)
                })
              } else {
                console.warn('not starting idle watch in local dev environment!')
                const footerHideButton: HTMLElement = document.querySelector(".btn-footer-hide")!
                if (footerHideButton) footerHideButton.click() // hide footer in local dev environment
              }
              this.setProfilePicture(res.eid)
              this.userSubscription.unsubscribe()
              this.authSubscription.unsubscribe()
            } else {
              console.error('getting user data returned null')
              this.userSubscription.unsubscribe()
              this.authSubscription.unsubscribe()
              if (res === null) { // if response does not have an eid and is null, then there does not exist an access token and the user must be timed out
                this.authorized = 'not authorized'
                this.router.navigate(['timeout'])
              } else this.rebarAuthService.login()
            }
          }, error: (error: any) => {
            console.error(error)
            this.navMetaData.userName = this.rebarAuthService.getUser() || ''
            this.authorized = 'not authorized'
            this.router.navigate(['api-down'])
            this.userSubscription.unsubscribe()
            this.authSubscription.unsubscribe()
          }
        })
      }
    })
    this.navMetaData = {
      userName: "",
      isAdmin: false,
      env: environment.environment,
      isIEOrEdge: false,
      brand: {
        main: "EID Security Compliance Tool",
        // subtitle: 'Enable Me Utility',
        routerLink: '/dashboard',
      },
      leftNavItems: [],
      secondEndItem: {
        type: 'help',
        name: 'Help',
        routerLink: '/help',
        admin: false
      },
      endItem: {
        type: 'user',
        name: '',
        admin: false,
        options: [
          {
            type: 'logout',
            name: 'logout',
            function: this.signOut.bind(this)
          },
        ],
      },
    }

    if (this.env !== 'prod') {
      Object.entries(this.demoEids).forEach(([key, value]) => {
        let option = {
          type: 'link',
          name: key,
          function: this.switchUser.bind(this, key)
        }
        this.navMetaData.endItem.options.unshift(option)
      })
    }
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe()
    if (this.userSubscription) this.userSubscription.unsubscribe()
    if (this.tokenSubscription) this.tokenSubscription.unsubscribe()
    if (this.onIdleEndSubscription) this.onIdleEndSubscription.unsubscribe()
    if (this.onTimeoutSubscription) this.onTimeoutSubscription.unsubscribe()
    if (this.onIdleStartSubscription) this.onIdleStartSubscription.unsubscribe()
    if (this.onTimeoutWarningSubscription) this.onTimeoutWarningSubscription.unsubscribe()
    if (this.avatarSubscription) this.avatarSubscription.unsubscribe()
  }

  setProfilePicture(eid: string) {
    if (!eid.includes('test.')) {
      this.avatarSubscription = this.dataService.getProfilePicture(eid).subscribe({
        next: (res) => {
          // reset the Input navMetaData to force child component to re-render and add avatar
          let navMetaDataClone = clone(this.navMetaData)
          this.navMetaData = null
          navMetaDataClone.endItem.avatar = res
          this.navMetaData = navMetaDataClone
          this.avatarSubscription.unsubscribe()
        }, error: (error) => {
          console.error(error)
          this.avatarSubscription.unsubscribe()
        }
      })
    }
  }

  signOut(): void {
    this.rebarAuthService.logout()
  }

  startIdle(): void {
    // sets an idle timeout of 55 minutes, for testing purposes.
    this.idle.setIdle(this.idlePeriod)
    // sets a timeout period of 5 minutes. after an hour of inactivity, the user will be considered timed out.
    this.idle.setTimeout(this.timeoutPeriod)
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle'
      if (this.modalRef) this.modalRef.close()
    })
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!'
      this.timedOut = true
      if (this.modalRef) this.modalRef.componentInstance.text = 'Your session has been timed out. Please logout and then log back in again.' // this.modalRef.close()
      this.dataService.timeout()
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
    })
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = '<div>You have been idle too long. You will time out in <b>' + countdown + '</b> seconds!</div>'
      if (countdown === this.timeoutPeriod) {
        // alert(this.idleState)
        this.modalRef = this.modalService.open(InformationalModalComponent, { windowClass: "error-modal" })
        this.modalRef.componentInstance.errorMessage = true
      }
      this.modalRef.componentInstance.text = this.idleState
    })

    this.reset()
  }

  reset() {
    this.idle.watch()
    this.idleState = 'Started.'
    this.timedOut = false
  }

  // For DEMO purposes
  switchUser(name: string) {
    if (name.includes('test.') && this.env !== 'prod') {
      this.navMetaData.userName = name
      this.dataService.setLoggedInUser(this.demoEids[name])
    } else if (this.realEid.eid === name) {
      this.navMetaData.userName = name
      this.setProfilePicture(name)
      this.dataService.setLoggedInUser(this.realEid)
    }
  }
}
