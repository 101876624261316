import { Component, OnInit, OnDestroy } from '@angular/core'
import { RebarAuthService } from '../core/auth/rebar.auth.service'
import { DataService } from '../core/services/data.service'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'

@Component({
  selector: 'rebar-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit, OnDestroy {
  userSubscription: Subscription = new Subscription()

  constructor(private rebarAuthService: RebarAuthService, private router: Router, private dataService: DataService) { }

  ngOnInit(): void {
    this.userSubscription = this.dataService.userSubject.subscribe({
      next: (res: any) => {
        // console.log(res)
        if (res && res.eid) this.router.navigate(['/dashboard'])
      }})
   }

   ngOnDestroy(): void {
      this.userSubscription.unsubscribe()
    }

  logout(): void {
    this.rebarAuthService.logout()
  }
}
