import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../core/services/data.service'
import { Subscription, Observable, concat, EMPTY } from 'rxjs'
import { catchError, toArray } from 'rxjs/operators'
import { getPendingApprovalObject, emptyObject, checkConditionalAccess } from '../core/utilities/helper-functions'
import { SpinnerOverlayService } from '../core/services/SpinnerOverlayService'


@Component({
  selector: 'app-enable-eid-modal',
  templateUrl: './enable-eid-modal.component.html',
  styleUrls: ['./enable-eid-modal.component.scss']
})
export class EnableEidModalComponent implements OnInit, OnDestroy {
  @Input() metaData: any
  @Output() next = new EventEmitter()
  public enableFormGroup!: UntypedFormGroup
  updating: boolean = false
  updated: boolean = false
  submissionError: string = ''
  confirmedDevices: string[] = []

  checkConditionalAccess = checkConditionalAccess

  private enableSubscription!: Subscription

  constructor(public activeModal: NgbActiveModal, private dataService: DataService, private fb: UntypedFormBuilder, private spinnerOverlayService: SpinnerOverlayService) { }

  ngOnInit(): void {
    this.enableFormGroup = this.fb.group({
      confirm: ['', Validators.required]
    })
    if (this.metaData.selectedEid.devices && this.metaData.selectedEid.devices.length > 0) {
      this.metaData.selectedEid.devices.forEach((device: any) => {
        if (device.softConditionalAccess === false || device.hardConditionalAccess === false) this.confirmedDevices.push(device.id)
      })
    }
  }

  ngOnDestroy(): void {
    if (this.enableSubscription) this.enableSubscription.unsubscribe()
  }

  confirmDevice(e: any, device: any): void {
    if (e.target.checked) {
      this.confirmedDevices.push(device.id)
    } else {
      // remove device from confirmedDevices array if it exists
      let index = this.confirmedDevices.indexOf(device.id)
      if (index !== -1) this.confirmedDevices.splice(index, 1)
    }
  }

  finishingEnabling(): void {
    this.updating = false
    this.updated = true
    this.submissionError = ''
    this.next.emit(this.confirmedDevices)
    this.enableSubscription.unsubscribe()  
    this.spinnerOverlayService.hide()
  }

  failEnabling(error: any): void {
    console.error(error)
    this.updating = false
    this.updated = true
    this.enableSubscription.unsubscribe()
    this.spinnerOverlayService.hide()
  }

  enable(): void {
    this.spinnerOverlayService.show()
    this.updated = false
    this.updating = true
    this.submissionError = ''
    // if ((this.metaData.loggedInUser.level <= 6 || this.metaData.checkedADGroup) || !emptyObject(this.metaData.selectedEid.pendingApprovals)) {
    if ((this.metaData.checkedADGroup) || !emptyObject(this.metaData.selectedEid.pendingApprovals)) {
      let body: any = { eid: this.metaData.selectedEid.eid, enabledBy: this.metaData.loggedInUser.eid, disabledReason: (this.metaData.selectedEid.complianceFlag || '') }
      if (this.confirmedDevices.length > 0) body.devices = this.confirmedDevices
      this.enableSubscription = this.dataService.enableEID(body).subscribe({
        next: (res: any) => {
          console.log("request response:"); console.log(res)
          if (this.confirmedDevices.length > 0) {
            this.enableDevices()
          } else this.finishingEnabling()
        },
        error: (error: any) => {
          this.failEnabling(error)
          this.submissionError = "Could not enable EID because: " + (error.error.message || error.error || error)
        }
      })
    } else {
      this.enableSubscription = this.dataService.updatePendingApprovals(getPendingApprovalObject(this.metaData.selectedEid, this.metaData.loggedInUser, this.confirmedDevices)).subscribe({
        next: (res: any) => {
          console.log("request response:"); console.log(res)
          if (this.confirmedDevices.length > 0) {
            this.enableDevices()
          } else this.finishingEnabling()
        },
        error: (error: any) => {
          this.failEnabling(error)
          this.submissionError = "Could not enable EID because: " + (error.error.message || error.error || error)
        }
      })
    }
  }

  enableDevices(): void {
    // function to concat observables for enabling confirmedDevices
    let observables: Observable<any>[] = []
    this.confirmedDevices.forEach((deviceId: string) => {
      observables.push(this.dataService.removeConditionalAccess(deviceId, "", this.metaData.selectedEid.eid, 'temp')) // Todo: replace temp with incident number field
    })
    this.enableSubscription = concat(...observables).pipe(catchError(error => { console.error(error); return EMPTY }), toArray()).subscribe({
      next: (res: any) => {
        console.log("request response:"); console.log(res)
        // check if res is an array of non-null values
        if (res.filter((value: any) => value !== null).length === this.confirmedDevices.length) this.finishingEnabling()
        else {
          this.failEnabling('Could not enable device(s)')
          this.submissionError = "Enabled EID but could not enable device(s)"
        }
      },
      error: (error: any) => {
        this.failEnabling(error)
        this.submissionError = "Enabled EID but could not enable device(s) because: " + (error.error.message || error.error || error)
      }
    })
  }
}