import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map, delay, tap, catchError, mergeMap } from 'rxjs/operators'
import { of, iif, forkJoin, Observable, BehaviorSubject, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { RebarAuthService } from '../auth/rebar.auth.service'
import { getToken, checkIfTokenIsNotExpired, helpCatchError, catchErrorAndReroute, removeTokens } from '../utilities/auth-util'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class DataService {
  url: string
  env: string
  pageCount: string = "100"
  loggedInUserId: string = ''
  loggedInUser: any = null
  getUserDataEndpoint: string = "/getUserData"

  userSubject: Subject<string> = new BehaviorSubject<any>({})

  getJsonCredentialsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(private http: HttpClient, private rebarAuthService: RebarAuthService, private router: Router) {
    this.url = environment.apiURL
    this.env = environment.environment
    // this.getUserDataEndpoint = (this.env === 'prod') ? "/getUserData" : "/getUserData/loggedInUser" // to retrieve data for an eid: most eids will not return successful data for "/getUserData" in non-prod so only use that endpoint for prod
  }

  getAccessToken(type: string): any {
    let scopes = type === 'api' ? environment.scopes.api : environment.scopes.graph
    let accessToken = getToken('AccessToken', scopes)
    if (accessToken) {
      if (!checkIfTokenIsNotExpired(accessToken)) {
        console.warn('EXPIRED TOKEN!')
        return this.rebarAuthService.acquireTokenSilent({scopes: scopes}).pipe(
          catchError((error: any) => helpCatchError(error))).pipe(map((res: any) => res = res.accessToken))
      } else return of(accessToken.secret)
    } else if (type === 'graph') {
      return this.rebarAuthService.acquireTokenSilent({scopes: scopes}).pipe((map((res: any) => res = res.accessToken)))
    } else return of(null)
  }

  timeout(): void {
    const tokenNames: string[] = ['IdToken', 'AccessToken', 'RefreshToken']
    removeTokens(tokenNames.join())
    this.router.navigate(['timeout'])
  }

  setForkedData(endpoint: string, subject: Subject<any>) {
    this.getPage(endpoint, "1").subscribe({
      next: (data) => { // get first 500 entries and the count
        let rowCount = data.count
        let pages = Math.ceil(rowCount / Number(this.pageCount)) // calculate number of 500-entry-limit more API calls needed
        let returnArray: any = data.rows
        if (pages > 1) {
          let pagesBatch = []
          for (let p = 2; p <= pages; p++) { // queue API calls
            let response = this.getPage(endpoint, p.toString())
            pagesBatch.push(response)
          }
          forkJoin(pagesBatch).subscribe({
            next: (results) => { // call API calls and concatenate their results
              for (let r = 0; r < results.length; r++) {
                let rows = (results[r] as any).rows
                if (rows) { returnArray = returnArray.concat(rows) }
              }
              subject.next(returnArray)
            }, error: (error) => {
              subject.error(error)
            }})
        } else {
          subject.next(returnArray)
        }
    }, error: (error) => {
      subject.error(error)
    }})
  }

  getPage(endpoint: string, page: string): Observable<any> {
    return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.get<any>(this.url + endpoint, {
        params: {
          limit: this.pageCount,
          page: page
        },
        headers: {
          "Authorization": "Bearer " + res,
          "Content-Type": "application/json"
        }}))
    )
  }

  getEid(eid: string): Observable<any> {
    eid = eid.split("@")[0] || eid // remove domain from eid
      const endpoint: string = (!eid.includes('test.')) ? (this.getUserDataEndpoint) : "/getUserData" // only use the getUserData endpoint for test data
      const payload: any = { "eid": eid }
      return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.post<any>(this.url + endpoint, payload, {
        headers: {
          "Authorization": "Bearer " + res,
          "Content-Type": "application/json"
        }})))
  }

  enableEID(body: any): Observable<any>  {
    let endpoint = "/enableEID"
    return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.post<any>(this.url + endpoint, body, {
      headers: {
        "Authorization": "Bearer " + res,
        "Content-Type": "application/json"
      }})))
  }

  updatePendingApprovals(pendingApproval: any): Observable<any>  {
    let endpoint = "/pendingApprovals"
    return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.post<any>(this.url + endpoint, pendingApproval, {
      headers: {
        "Authorization": "Bearer " + res,
        "Content-Type": "application/json"
      }})))
  }

  setLoggedInUserId(user: string): void {
    this.loggedInUserId = user
  }

  getLoggedInUser(): any {
    return this.loggedInUser || null
  }

  setLoggedInUser(user: any): void {
    this.loggedInUser = user
    this.userSubject.next(this.loggedInUser)
  }

  getUserData(): Observable<any>  {
    if (this.loggedInUser) {
      this.userSubject.next(this.loggedInUser)
      return of(this.loggedInUser)
    } 
    // else if (this.env === 'local') {
    //     const defaultUser = { eid: 'palak.lal', level: '9', country: 'USA' }
    //     this.setLoggedInUser(defaultUser)
    //     return of(defaultUser)
    // }
    else {
      const payload: any = { "eid" : this.rebarAuthService.getUser() }
      return this.getAccessToken('api').pipe(
        catchError((error: any) => helpCatchError(error)))
        .pipe(mergeMap((accessToken: any) =>
          iif( // if accessToken is not null, send API request else return null
            () => accessToken,
            this.http.post<any>((this.url + '/getUserData/loggedInUser'), payload, {
              headers: {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
              }
              }).pipe(
              catchError((error: any) =>
                catchErrorAndReroute(error, this.router)
              )
            ),
            of(accessToken)
          )
        )).pipe(tap(res => this.setLoggedInUser(res)))
    }
  }

  getProfilePicture(eid: string): Observable<any>  {
    // console.log(eid)
    eid = eid.split("@")[0] || eid // remove domain from eid
    let url = "https://graph.microsoft.com/v1.0/users/" + eid + "@accenture.com/photo/$value"
    return this.getAccessToken('graph').pipe(mergeMap((res: any) => this.http.get<any>(url, {
      responseType: 'blob' as 'json',
      headers: {
        "Authorization": "Bearer " + res,
        "Content-Type": "application/json"
      }
    }).pipe(
      catchError((error: any) => {
        console.error(error)
        return of(null)
      })
    )
    ))
    // MOCK data
    // return this.http.get('../../assets/sample_profile_picture.jpg', { responseType: 'blob' })
  }

  getId(eid: string, graphToken: any) {
    let url = 'https://graph.microsoft.com/beta/users?$select=id,displayName&$search=("displayName:'+eid+'" OR "mail:'+eid+'" OR "userPrincipalName:'+eid+'" OR "givenName:'+eid+'" OR "surName:'+eid+'" OR "otherMails:'+eid+'")&$top=20&$count=true'
    return this.http.get<any>(url, {
      headers: {
        "Authorization": "Bearer " + graphToken,
        "Content-Type": "application/json",
        "ConsistencyLevel": "eventual"
      }
    }).pipe(
      catchError((error: any) => {
        console.error(error)
        return of(null)
      }),
      map((res: any) => {
        if (res && res.value[0]) return res.value[0].id
        else return null
      })
    )
  }

  // write a function to POST to endpoint /checkPMTCompliance that takes in a device name in body and returns PMT Compliance
  checkPMTCompliance(deviceName: string): Observable<any> {
    let endpoint = "/device/checkPMTCompliance"
    let body = { device: deviceName }
    return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.post<any>(this.url + endpoint, body, {
      headers: {
        "Authorization": "Bearer " + res,
        "Content-Type": "application/json"
      }})))
  }

  checkDevice(deviceId: string): Observable<any> {
    let endpoint = "/device/checkAttribute"
    let body = { device: deviceId }
    return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.post<any>(this.url + endpoint, body, {
      headers: {
        "Authorization": "Bearer " + res,
        "Content-Type": "application/json",
      }}).pipe(
        catchError((error: any) => {
          console.error(error)
          if (error.error.text) return of({error: error.error.text})
          else if (error.error.message) return of({error: error.error.message})
          else return of({ error: true })
        })
        // ,map((res: any) => {
        //   if (res) return res
        //   else return { error: true }
        // })
      )
    ))
  }

  getDevicesAttributes(devices: {}): Observable<any> {
    let endpoint = "/device/getDevicesWithAttributes"
    let body = { devices: devices }
    return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.post<any>(this.url + endpoint, body, {
      headers: {
        "Authorization": "Bearer " + res,
        "Content-Type": "application/json",
      }}).pipe(
        catchError((error: any) => {
          console.error(error)
          if (error.error.text) return of({error: error.error.text})
          else if (error.error.message) return of({error: error.error.message})
          else return of({ error: true })
        })
      )
    ))
  }

  // create function to post to endpoint /devices/removeConditionalAccess that takes in a device id in body
  removeConditionalAccess(deviceId: string, computerName: string, eid: string, inc: string): Observable<any> {
    let endpoint = "/device/removeConditionalAccess"
    let body = { "device": deviceId, name: computerName, "eid": eid, incident: inc }
    return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.post<any>(this.url + endpoint, body, {
      responseType: 'blob' as 'json',
      headers: {
        "Authorization": "Bearer " + res,
        "Content-Type": "application/json"
      }})))
  }

  getDevicesFor(eid: string): Observable<any> {
    eid = eid.split("@")[0] || eid // remove domain from eid
    let endpoint = "/device/getDevices"
    let body = { "user": eid + "@accenture.com" }
    return this.getAccessToken('api').pipe(mergeMap((res: any) => this.http.post<any>(this.url + endpoint, body, {
      headers: {
        "Authorization": "Bearer " + res,
        "Content-Type": "application/json"
      }})))
  }

  isInADGroup(eid: string) { // NO LONGER USED
    eid = eid.split("@")[0] || eid // remove domain from eid
    let url = `https://graph.microsoft.com/v1.0/users/` + eid + `@accenture.com/transitiveMemberOf?$select=id, displayName&$filter=id eq 'e094d310-22f6-422e-9e87-bae2770e4351'&$search="displayName:LTSS.193689"`
    return this.getAccessToken('graph').pipe(mergeMap((graphToken: any) => this.http.get<any>(url, {
      headers: {
        "Authorization": "Bearer " + graphToken,
        "Content-Type": "application/json",
        "ConsistencyLevel": "eventual"
      }
    }).pipe(
      catchError((error: any) => {
        console.error(error)
        return of(false)
      }),
      map((res: any) => (res.value && res.value.length > 0))
    )
    ))
  }

  isInLeaversGroup(eid: string) { // NO LONGER BEING USED
    const search = "!vyopta_admin" // todo: update with actual leavers group AD group
    return this.getAccessToken('graph').pipe(mergeMap((graphToken: any) => this.getId(eid, graphToken).pipe(
      mergeMap((localAccountId: any) =>
        iif(
          () => localAccountId,
          this.http.get<any>('https://graph.microsoft.com/beta/users/' + localAccountId + '/memberOf/$/microsoft.graph.group?$select=displayName&$search=("displayName:' + search + '")', {
            headers: {
              "Authorization": "Bearer " + graphToken,
              "Content-Type": "application/json",
              "ConsistencyLevel": "eventual"
            }
          }).pipe(
            catchError((error: any) => {
              console.error(error)
              return of(null)
            }),
            map((res: any) => (res.value && res.value.length > 0))
          ),
          of(null)
        )
    ))))
  }
}
