import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular'
import { RebarAuthModule } from './rebar.auth.module'
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser'
import { from, Observable, Subject, of, BehaviorSubject } from 'rxjs'
import { filter, map, takeUntil, catchError } from 'rxjs/operators'

@Injectable({
  providedIn: RebarAuthModule,
})
export class RebarAuthService {
  private readonly destroying$ = new Subject<void>();
  public authObserver$: Observable<boolean>;
  authenticatedSubject: Subject<any> = new BehaviorSubject<any>(null) // custom subject to track if user is authenticated, primarily in app.component

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private auth: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    if (this.authenticationEnabled()) {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          ),
          takeUntil(this.destroying$)
        )
        .subscribe(() => {
          this.checkAndSetActiveAccount();
          if (this.authenticationEnabled() && !this.isUserAuthenticated()) {
            this.login();
          }
        });
      this.authObserver$ = this.msalBroadcastService.inProgress$.pipe(
        map((status) => {
          return status === InteractionStatus.None;
        }),
        takeUntil(this.destroying$)
      );
    } else {
      this.authObserver$ = of(true);
    }
  }
  public isUserAuthenticated(): boolean {
    let temp = this.auth.instance.getAllAccounts().length > 0
    this.authenticatedSubject.next(temp)
    return this.auth.instance.getAllAccounts().length > 0;
  }

  public authenticationEnabled(): boolean {
    return environment.providers !== 'mock';
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.auth.instance.getActiveAccount();

    if (!activeAccount && this.auth.instance.getAllAccounts().length > 0) {
      const accounts = this.auth.instance.getAllAccounts();
      this.auth.instance.setActiveAccount(accounts[0]);
    }
  }

  public login(): void {
    if (this.authenticationEnabled()) {
      const activeAccount = this.auth.instance.getActiveAccount();
      console.log(activeAccount)
      if (!activeAccount) {
        if (this.auth.instance.getAllAccounts().length > 0) {
          const accounts = this.auth.instance.getAllAccounts();
          this.auth.instance.setActiveAccount(accounts[0]);
        } else {
          this.loginRedirect()
        }
      }
    }
  }

  public loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.auth.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.auth.loginRedirect();
    }
  }

  public acquireTokenSilent(scopes: any) {
    return from(this.auth.instance.acquireTokenSilent(scopes)).pipe(catchError(e => {
      console.error(e)
      this.loginRedirect()
      throw(e)
    }))
  }

  public logout(): void {
    this.auth.logout()
  }

  public getUser(): string | undefined {
    return this.auth.instance.getAllAccounts()[0]?.username.split('@')[0]
  }

  public getHomeAccountID(): string | undefined {
    return this.auth.instance.getAllAccounts()[0]?.homeAccountId;
  }
}
