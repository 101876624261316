import { Component, OnInit, Input } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms'
import { DataService } from '../../core/services/data.service'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Subscription } from 'rxjs'

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public formGroup!: UntypedFormGroup
  @Input() metaData: any

  customFields: any[] = []
  btnConfirmText = 'CONFIRM';
  isAccepted = false;
  incident: string = ''

  updated: boolean = false
  updating: boolean = false
  submissionError: boolean = false
  currentButton: any = { text: '' }
  progress: string = ''

  formSubscription: Subscription = new Subscription

  svg!: SafeHtml
  defaultSVG = "<svg xmlns=\"http://www.w3.org/2000/svg\" enable-background=\"new 0 0 24 24\" width=\"38\" height=\"38\" viewBox=\"-2 -2 28 28\" fill=\"#A100FF\"><g><rect fill=\"none\" height=\"24\" width=\"24\"/><path d=\"M4,9h7V6H4V9z M4,18h9v-3H4V18z\" opacity=\".3\"/><path d=\"M13,11H4c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h9V11z M4,9h7V6H4V9z M15,20H4c-1.1,0-2-0.9-2-2v-3c0-1.1,0.9-2,2-2h11V20z M4,18h9v-3H4V18z M22,9h-2l2-5h-7v7h2v9L22,9z M4.75,17.25h1.5v-1.5h-1.5V17.25z M4.75,8.25h1.5v-1.5h-1.5V8.25z\"/></g></svg>"

  constructor(private dataService: DataService, private sanitizer: DomSanitizer, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.svg = this.sanitizer.bypassSecurityTrustHtml(this.metaData.icon || this.defaultSVG);
    this.customFields = this.metaData.rows.flat()
    let formControls: {[index: string]:any} = {}
    this.customFields.forEach(customField => {
      let value = null
      let disabled: boolean = typeof customField.disabled === 'boolean' ? customField.disabled : false // if customField's disabled property is custom (like if a ticketNumber depends on there being falsey boolean inputs to be enabled), temporarily set to false
      formControls[customField.name] = new UntypedFormControl({ value: value, disabled: disabled }, customField.validators)
    })
    this.formGroup = new UntypedFormGroup(formControls)
  }

  ngOnDestroy(): void {
    if (this.formSubscription) this.formSubscription.unsubscribe()
  }

  getIncdentField(): any {
    return this.formGroup.get('incident')
  }

  isIncidentFieldInvalid() {
    const control = this.formGroup.get('incident')
    return control && control.invalid && (control.dirty || control.touched)
  }

  select(formcontrol: string, value: any): void {
    this.formGroup.controls[formcontrol].setValue(value)
    this.formGroup.controls[formcontrol].markAsDirty()
   // event.stopPropagation()
  }

  submit(button: any): void {
    if (button.text === 'Close') button.callback()
      
    this.currentButton = button
    this.updating = true
    this.updated = false
    button.updating = true
    button.updated = false
    let payload = this.formGroup.getRawValue()
    if (button.text === 'Enable Device') {
      this.formSubscription = this.dataService.removeConditionalAccess(this.metaData.deviceID, this.metaData.deviceName, this.metaData.eid, payload.incident).subscribe({
        next: (res: any) => {
          // console.log("request response:"); console.log(res)
          if (button.callback) {
            try {
              button.callback(payload)
            } catch(error) {
              console.log(error)
            }
          } else {
            this.updating = false
            this.updated = true
            button.updating = false
            button.updated = true
            this.submissionError = false
          }
        },
        error: (error: any) => {
          this.error(error, button)
        }
      })
    }
    else if (button.callback) {
      button.callback()
    }
  }

  success(button?: any): void { // to be called within user's local callback, etc function upon successful API call, etc. Optionally pass the button to be reset
    this.updating = false
    this.updated = true
    this.submissionError = false
    if (button) {
      button.updated = true
      button.updating = false
    }
  }

  error(error: any, button?: any): void {
    console.error(error)
    this.updating = false
    this.updated = false
    this.submissionError = true
    if (button) {
      button.text = 'Close'
      button.updating = false
      button.updated = false
    }
  }
}
